.sd-item {
  padding: calcSize(1.5) 0;
}

.sd-item--disabled.sd-item--disabled .sd-item__decorator,
.sd-item__decorator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calcSize(3);
  height: calcSize(3);
  box-sizing: border-box;
  background-color: $editor-background;
  box-shadow: $shadow-inner;
  border: none;
  flex-shrink: 0;
  margin-top: calc(calc(multiply(1.5, $font-editorfont-size) - calcSize(3)) / 2);
}

.sd-item--checked .sd-item__decorator {
  box-shadow: none;
}

.sd-item__control:focus+.sd-item__decorator {
  box-shadow: 0 0 0 2px $primary;
  background: $question-background;
  outline: none;
}

.sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
  background: $background-dim-dark;
  outline: none;
}

.sd-item--checked .sd-item__decorator {
  background: $primary;
}

.sd-item__control-label {
  font-family: $font-editorfont-family;
  font-style: normal;
  font-weight: $font-editorfont-weight;
  line-height: multiply(1.5, $font-editorfont-size);
  font-size: $font-editorfont-size;
  color: $font-questiontitle-color;
  white-space: normal;
  word-break: break-word;
  width: 100%;
  text-align: left;
}

.sd-item--disabled .sd-item__control-label {
  color: $font-questiontitle-color;
  opacity: 0.25;
}

.sd-root--readonly .sd-item--disabled .sd-item__control-label {
  color: $font-questiontitle-color;
}

.sd-item--error .sd-item__decorator {
  background: $red-light;
}