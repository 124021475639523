@import "../defaultV2-theme/variables.scss";
@import "../defaultV2-theme/mixins.scss";

.sv-ranking {
  outline: none;
  user-select: none;
  -webkit-user-select: none;
}

.sv-ranking-item {
  cursor: pointer;
  position: relative;
}

.sv-ranking-item:focus .sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking-item:hover:not(:focus) .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-question--disabled .sv-ranking-item:hover .sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking-item:focus {
  outline: none;
}

.sv-ranking-item:focus .sv-ranking-item__icon--focus {
  visibility: visible;
  top: calcSize(0.6);
  height: calcSize(3);
}

.sv-ranking-item:focus .sv-ranking-item__index {
  background: $background;
  outline: calcSize(0.25) solid $primary;
}

.sv-ranking-item__content.sv-ranking-item__content {
  display: flex;
  align-items: center;
  line-height: 1em;
  padding: calcSize(0.5) 0px;
  border-radius: calcSize(12.5);
}

.sv-ranking-item__icon-container {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  flex-shrink: 0;
  width: calcSize(3);
  height: calcSize(4);
}

.sv-ranking-item--disabled.sv-ranking-item--disabled {
  cursor: initial;

  .sv-ranking-item__icon-container.sv-ranking-item__icon-container {
    .sv-ranking-item__icon.sv-ranking-item__icon {
      visibility: hidden;
    }
  }
}

.sv-ranking-item__icon.sv-ranking-item__icon {
  visibility: hidden;
  fill: $primary;
  position: absolute;
  top: calcSize(1);
  width: calcSize(1.75);
  height: calcSize(2);
}

.sv-ranking-item__index.sv-ranking-item__index {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: $primary-light;
  color: $font-questiontitle-color;
  font-size: $font-editorfont-size;
  border-radius: 100%;
  border: calcSize(0.25) solid transparent;
  width: calcSize(5);
  height: calcSize(5);
  line-height: calcLineHeight(1.5);
  box-sizing: border-box;
  font-weight: 600;
  margin-left: calcSize(0);

  svg {
      fill: $font-questiontitle-color;
      width: $font-editorfont-size;
      height: $font-editorfont-size;
  }
}

.sv-ranking-item__text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $font-questiontitle-color;
  font-size: $font-editorfont-size;
  line-height: multiply(1.5, $font-editorfont-size);
  margin: 0 calcSize(2);
}

.sd-ranking--disabled .sv-ranking-item__text {
  color: $font-questiontitle-color;
  opacity: 0.25;
}

.sv-ranking-item--disabled .sv-ranking-item__text {
  color: $font-questiondescription-color;
  opacity: 0.25;
}

.sv-ranking-item__ghost.sv-ranking-item__ghost {
  display: none;
  background-color: $background-dim;
  border-radius: calcSize(12.5);
  width: calcSize(31);
  height: calcSize(5);
  z-index: 1;
  position: absolute;
  left: 0;
  top: calcSize(0.5);
}

[dir="rtl"] .sv-ranking-item__ghost {
  left: initilal;
  right: calcSize(5);
}

.sv-ranking-item--ghost .sv-ranking-item__ghost {
  display: block;
}

.sv-ranking-item--ghost .sv-ranking-item__content {
  visibility: hidden;
}

.sv-ranking-item--drag .sv-ranking-item__content {
  box-shadow: $shadow-large;
  border-radius: calcSize(12.5);
}

.sv-ranking--drag .sv-ranking-item:hover .sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking-item--drag .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-ranking--mobile .sv-ranking-item__icon--hover {
  visibility: visible;
  fill: $foreground-light;
}

.sv-ranking--mobile.sv-ranking--drag .sv-ranking-item--ghost .sv-ranking-item__icon.sv-ranking-item__icon--hover {
  visibility: hidden;
}

.sv-ranking--mobile.sv-ranking-shortcut {
  max-width: 80%;
}

.sv-ranking--mobile {
  .sv-ranking-item__index.sv-ranking-item__index {
    margin-left: 0;
  }

  .sd-element--with-frame .sv-ranking-item__icon {
    margin-left: 0;
  }
}

.sv-ranking--design-mode .sv-ranking-item:hover .sv-ranking-item__icon {
  visibility: hidden;
}

.sv-ranking--disabled {
  opacity: 0.8;
}

.sv-ranking-shortcut[hidden] {
  display: none;
}

.sv-ranking-shortcut .sv-ranking-item__icon {
  fill: $primary;
}

.sv-ranking-shortcut .sv-ranking-item__text {
  margin-right: calcSize(4);
}

.sv-ranking-shortcut .sv-ranking-item__icon--hover {
  visibility: visible;
}

.sv-ranking-shortcut .sv-ranking-item__icon {
  width: calcSize(1.75);
  height: calcSize(2);
  top: calcSize(1);
}

.sv-ranking-shortcut {
  .sv-ranking-item__content {
    padding-left: calcSize(0.5);
  }

  .sv-ranking-item__icon-container {
    margin-left: calcSize(1);
  }
}

.sv-ranking--select-to-rank {
  display: flex;
}

.sv-ranking--select-to-rank-vertical {
  flex-direction: column-reverse;

  .sv-ranking__containers-divider {
    margin: calcSize(3) 0;
    height: 1px;
  }

  .sv-ranking__container--empty {
    padding-top: calcSize(1);
    padding-bottom: calcSize(1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sv-ranking--select-to-rank-horizontal {
  .sv-ranking__container {
    max-width: calc(50% - 1px);
  }

  .sv-ranking__containers-divider {
    width: 1px;
  }

  .sv-ranking__container--to {
    .sv-ranking-item {
      left: calcSize(2);
    }
  }

  .sv-ranking__container--empty {
    &.sv-ranking__container--to {
      //padding-right: calcSize(3);

      .sv-ranking-item {
        left: initial;
      }

      .sv-ranking__container-placeholder {
        padding-left: calcSize(5);
      }
    }

    &.sv-ranking__container--from {
      //padding-left: calcSize(3);

      .sv-ranking__container-placeholder {
        padding-right: calcSize(5);
      }
    }
  }
}

.sv-ranking__container-placeholder {
  color: var(--sjs-font-questiondescription-color, #{$foreground-dim-light});
  @include defaultFont;
  white-space: break-spaces;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100%;
  height: 100%;
  padding-top: calcSize(0.5);
  padding-bottom: calcSize(0.5);
}

.sv-ranking__container {
  flex: 1;
  //max-width: 100%;
}

.sv-ranking__container--empty {
  //padding: calcSize(8);
  box-sizing: border-box;
  text-align: center;
}

.sv-ranking__containers-divider {
  background: var(--sjs-border-default, #{$border-inside});
}

.sv-ranking__container--from {
  .sv-ranking-item__icon--focus {
    display: none;
  }
}