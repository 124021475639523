@import "../variables.scss";

.sd-btn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: calcSize(2) calcSize(6);
  background: $question-background;
  box-shadow: $shadow-small;
  border-radius: calcCornerRadius(1);
  cursor: pointer;

  font-family: $font-family;
  font-style: normal;
  font-weight: 600;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
  text-align: center;
  color: $primary;
  border: none;
  outline: none;
}

.sd-btn--small {
  flex-grow: 1;
  padding: calcSize(1.5) calcSize(4);
}

.sd-btn:hover {
  background-color: $background-dark;
}

.sd-btn:focus {
  box-shadow: 0 0 0 2px $primary;
}

.sd-btn:disabled {
  color: $foreground;
  opacity: 0.25;
  pointer-events: none;
}

.sd-btn--action {
  background-color: $primary;
  color: $primary-foreground;
}

.sd-btn--action:hover {
  color: $primary-foreground;
  background-color: $primary-background-dark;
}

.sd-btn--action:disabled {
  color: $primary-foreground-disabled;
  pointer-events: none;
}

.sd-btn--danger {
  background-color: $red;
  color: $primary-foreground;
}

.sd-btn--danger:hover {
  background-color: $red;
  color: $primary-foreground;
}

.sd-btn--danger:disabled {
  color: $red-forecolor;
  pointer-events: none;
}