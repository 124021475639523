.sd-element--complex.sd-element--with-frame {
  padding-top: 0;
}

.sd-element--complex.sd-element--nested-with-borders>.sd-question__erbox--outside-question,
.sd-element--complex.sd-element--with-frame>.sd-question__erbox--outside-question {
  margin-top: 0;
  margin-bottom: 0;
}

.sd-element--complex>.sd-element__header {
  &:after {
    content: " ";
    display: block;
    position: relative;
    height: 1px;
    background: $border-light;
    bottom: 0;
  }
}

.sd-element--complex.sd-element--with-frame>.sd-element__header {
  padding-top: var(--sd-base-vertical-padding);
  padding-bottom: var(--sd-base-vertical-padding);
}

.sd-element--collapsed.sd-element--complex>.sd-element__header {
  padding-top: calcSize(2);
  padding-bottom: calcSize(2);
}

.sd-element--nested.sd-element--complex {
  &>.sd-element__header--location-top {
    &:after {
      bottom: calc(-0.5 * var(--sd-base-vertical-padding));
    }
  }
}

.sd-element--nested-with-borders {
  border: 1px solid $border-light;
  border-radius: calc(var(--sjs-corner-radius, 4px) - 4px);
  box-sizing: border-box;
  padding-left: var(--sd-base-padding);
  padding-right: var(--sd-base-padding);

  &>.sd-element__header--location-top {
    padding-top: calc(0.5 * var(--sd-base-vertical-padding));
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
  }

  &>.sd-question__erbox--outside-question {
    margin-left: calc(-1 * var(--sd-base-padding));
    margin-right: calc(-1 * var(--sd-base-padding));
    width: calc(100% + 2 * var(--sd-base-padding));
  }

  &>.sd-question__erbox--below-question {
    bottom: 0;
    margin-top: 0;
  }

  &.sd-element--collapsed {
    &>.sd-element__header--location-top {

      &:hover,
      &:focus-within {
        box-shadow: none;
      }
    }
  }
}

.sd-element--nested-with-borders,
.sd-element--complex.sd-element--with-frame {
  &>.sd-element__header--location-top {
    &:after {
      bottom: calc(-1 * var(--sd-base-vertical-padding));
      left: calc(-1 * var(--sd-base-padding));
      width: calc(100% + 2 * var(--sd-base-padding));
    }
  }
}

.sd-element--collapsed.sd-element--complex>.sd-element__header--location-top {
  &:after {
    display: none;
  }
}

.sd-question--empty.sd-question--complex {
  &>.sd-question__content {
    padding-top: 0;
    padding-bottom: 0;
  }

  &>.sd-question__content:first-child {
    padding-top: var(--sd-base-padding);
  }

  &>.sd-question__header--location-top {
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));

    &:after {
      display: none;
    }
  }
}