.sd-input.sd-tagbox:not(.sd-tagbox--empty):not(.sd-input--disabled) {
  height: auto;
  padding: calcSize(0.5);
  padding-inline-end: calcSize(6); //padding-right
  padding-inline-start: calcSize(0.5); //padding-left
}

.sd-tagbox.sd-tagbox--empty {
  // padding: calcSize(0.5) calcSize(6) calcSize(0.5) calcSize(2);
  // padding-block: calcSize(0.5);
  // padding-inline-end: calcSize(6);
  // padding-inline-start: calcSize(2);
}

.sd-tagbox_clean-button {
  height: multiply(1.5, $font-editorfont-size);
  padding: multiply(0.5, $font-editorfont-size);
  margin: auto 0;
}

.sd-input.sd-tagbox:focus-within {
  box-shadow: 0 0 0 2px $primary;
}

.sv-tagbox__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: calcSize(1) calcSize(1.5);
  background-color: $primary;
  border-radius: calcCornerRadius(0.5);
}

.sv-tagbox__item-text {
  color: $primary-foreground;
  min-width: calcSize(5.5);
  text-align: center;
  font-weight: 600;
}

.sv-tagbox__item:hover,
.sv-tagbox__item:focus,
.sv-tagbox__item:focus-within {
  .sd-tagbox-item_clean-button {
    display: flex;
    align-self: center;
  }
}

.sd-tagbox-item_clean-button {
  display: none;
  position: absolute;
  inset-inline-end: calcSize(1.5);
  padding: 0;
  padding-inline-start: calcSize(4);
  background: linear-gradient(270deg, $primary 53.12%, rgba(25, 179, 148, 0) 100%);
}

.sd-tagbox-item_clean-button-svg {
  display: block;
  padding: multiply(0.25, $font-editorfont-size);
  width: multiply(1, $font-editorfont-size);
  height: multiply(1, $font-editorfont-size);
}

.sd-tagbox-item_clean-button-svg:hover {
  border-radius: 100px;
  background: $primary-foreground-disabled;
}

.sd-tagbox-item_clean-button-svg use {
  fill: $primary-foreground;
}

.sd-tagbox__value.sd-dropdown__value {
  position: relative;
  gap: calcSize(0.5);
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  padding-inline: unset;
  margin-inline: unset;
  margin-block: unset;
}

.sd-tagbox__filter-string-input {
  width: 100%;
  display: flex;
  flex-grow: 1;
  // position: initial;
}

.sd-tagbox__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
  color: $foreground-light;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sd-tagbox-item_clean-button {
    background: linear-gradient(90deg, $primary 53.12%, rgba(25, 179, 148, 0) 100%);
  }
}

.sd-tagbox.sd-tagbox--empty {

  .sd-tagbox__hint,
  .sd-tagbox__hint-suffix-wrapper,
  .sd-tagbox__filter-string-input {
    width: 100%;
  }
}

.sd-tagbox__hint {
  display: flex;
  flex-grow: 1;
}

.sd-tagbox__hint-suffix-wrapper {
  position: relative;
  width: 100%;
}

.sd-dropdown__hint-suffix.sd-tagbox__hint-suffix {
  line-height: multiply(1.5, $font-editorfont-size);
  height: 100%;
  display: flex;
  align-items: center;
}

.sd-dropdown__hint-prefix.sd-tagbox__hint-prefix {
  line-height: multiply(1.5, $font-editorfont-size);
  height: 100%;
  display: flex;
  align-items: center;
}