.sjs_sp_placeholder {
    color: $font-questionplaceholder-color;
    font-size: $font-editorfont-size;
    line-height: multiply(1.5, $font-editorfont-size);
}

.sjs_sp_container {
    border: 1px dashed $border;
}

.sjs_sp_controls.sd-signaturepad__controls {
    right: calcSize(1);
    top: calcSize(1);
    left: auto;
    bottom: auto;
}
.sd-question--signature:not(.sd-question--answered) {
    .sd-signaturepad__controls {
        display: none;
    }
}
.sd-question--signature.sd-question--error {
    .sjs_sp_placeholder {
        background-color: $red-light;
    }
}

.sd-signaturepad__background-image {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}
